import React from "react";
import { Link } from "gatsby";
import OptimizedImage from "hooks/OptimizedImage";
import GlobalBadge from "assets/client/Global Badge 2023 - Fall 1.png";
import BlueBadge from "assets/client/blue_board.svg";
import ProBadge from "assets/client/pro.svg";
import Badge1 from "assets/client/badge1.svg";
import Badge2 from "assets/client/badge2.svg";
import Badge3 from "assets/client/badge3.svg";
import Ata from "assets/client/ata.svg";
import Usics from "assets/client/uscis.svg";
import Clutch from "assets/client/clutch.svg";

const badges = [
  {
    image: Badge1,
    text1: "ISO 9001:2015",
    text2: "Certification No. 108179",
  },
  {
    image: Badge2,
    text1: "ISO 18587:2017",
    text2: "Certification No. 001259",
  },
  {
    image: Badge3,
    text1: " ISO 17100:2015",
    text2: "Certification No. 001258",
  },
  {
    image: Ata,
    text1: "  ATA Corporate",
    text2: "Member Number 272444",
    width: "160px",
  },
  {
    link: "/uscis-translation-services",
    image: Usics,
    text1: " 100% guaranteed",
    text2: "acceptance by USCIS",
    width: "160px",
  },
  {
    url: "https://clutch.co/translation#leaders-matrix",
    image: Clutch,
    text1: "  Clutch Top Global",
    text2: "Translation Services 2023",
  },
  {
    url: "https://www.proz.com/business/37087",
    image: ProBadge,
    text1: "  ProZ.com Certified PRO Network",
    text2: "Member Since 2023",
  },
  {
    url: "https://www.proz.com/blueboard/26135",
    image: BlueBadge,
    text1: "  PROZ.com Blue Board",
    text2: "Business Member",
  },
];

export default function HomepageThirdComp() {
  return (
    <div className=" bg-white lg:bg-certified bg-cover bg-no-repeat font-opensans xs:font-secondary mt-[-1px] pt-28 lg:pt-0">
      <div className="mainContainer w-11/12 flex flex-col justify-center items-center mx-auto lg:pt-[26rem] xl:max-w-7xl 2xl:h-[60rem]">
        <h2 className="text-40 text-midBlue font-bold text-center">
          Globally Trusted Professional Translation Services by Experts
        </h2>

        <p className="text-[18px] text-left sm:text-center leading-relaxed pt-8">
          Any translation we deliver comes with the Tomedes guarantee. This
          means all our translations are accepted by institutions worldwide,
          which includes the USCIS for US immigration purposes. We are proud to
          be a part of the American Translator’s Association (ATA) and certified
          by the International Organization for Standardization (ISO).
        </p>

        <ul className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 place-content-center gap-[50px] pt-6 lg:pt-14 px-10 lg:max-w-[1030px]">
          {badges.map((elem, i) =>
            elem.url ? (
              <li
                key={i}
                className="flex flex-col items-center lg:min-w-[250px]"
              >
                <a href={elem.url} target="_blank">
                  <div className="h-[80px]">
                    <OptimizedImage
                      src={elem.image}
                      alt="icon"
                      className={`h-[80px] flex justify-center mx-auto ${
                        elem?.width ? `w-[${elem.width}]` : "w-[83px]"
                      }`}
                    />
                  </div>
                  <div className="flex flex-col">
                    <p className="text-[14px] text-[#313E5A] pt-4  text-center">
                      {elem.text1}
                    </p>
                    <p className="text-[14px] text-[#313E5A] text-center">
                      {elem.text2}
                    </p>
                  </div>
                </a>
              </li>
            ) : elem.link ? (
              <li
                key={i}
                className="flex flex-col  items-center  lg:min-w-[250px]"
              >
                <Link to={elem.link}>
                  <div className="h-[80px]">
                    <OptimizedImage
                      src={elem.image}
                      alt="icon"
                      className={`flex justify-center mx-auto ${
                        elem?.width ? `w-[${elem.width}]` : "w-[83px]"
                      }`}
                    />
                  </div>
                  <div className="flex flex-col">
                    <p className="text-[14px] text-[#313E5A] pt-4  text-center">
                      {elem.text1}
                    </p>
                    <p className="text-[14px] text-[#313E5A] text-center">
                      {elem.text2}
                    </p>
                  </div>
                </Link>
              </li>
            ) : (
              <li
                key={i}
                className="flex flex-col items-center lg:min-w-[250px]"
              >
                <div className="h-[80px]">
                  <OptimizedImage
                    src={elem.image}
                    alt="icon"
                    className={`flex justify-center mx-auto ${
                      elem?.width ? `w-[${elem.width}]` : "w-[83px]"
                    }`}
                  />
                </div>
                <div className="flex flex-col">
                  <p className="text-[14px] text-[#313E5A] pt-4  text-center">
                    {elem.text1}
                  </p>
                  <p className="text-[14px] text-[#313E5A] text-center">
                    {elem.text2}
                  </p>
                </div>
              </li>
            )
          )}
        </ul>
      </div>
    </div>
  );
}
