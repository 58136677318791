import React from "react";
import { Link } from "gatsby";
import OptimizedImage from "hooks/OptimizedImage";

export default function SupportedLang() {
  return (
    <section className="bg-midBlue py-20 relative ">
      <div className="lg:grid lg:grid-cols-2 lg:mx-auto lg:max-w-screen-lg">
        <div className="container mx-auto px-4  lg:pb-10 md:w-[600px]">
          <h2 className="text-white font-opensans xs:font-secondary text-40 mb-8 lg:font-bold font-semibold">
            Available in 120 Languages and 950+ Language Pairs
          </h2>
          <p className="text-gray font-opensans text-17 leading-8 lg:leading-6 sm:px-5 lg:max-w-5xl lg:px-0 mb-8">
            Can you bring us a linguistic challenge that we can't meet? Our team
            of global translators is unmatched in their knowledge and
            versatility, translating in over 120 different languages across the
            globe. Our expert, native-speakers have translated in virtually all
            professional fields, with over 95,000 client projects.
          </p>
          <p className="text-white sm:text-lightGray font-opensans text-17 leading-8 lg:leading-6 sm:px-5 lg:max-w-5xl mt-2 lg:px-0 mb-16">
            We invite you to tell us about your translation project. We love a
            tough challenge.
          </p>
          <Link
            to="/language-translation-service.php"
            className="uppercase learnmore-cn rounded-full bg-orange hover:bg-[#ff9a16] text-white cursor-pointer text-14 sm:text-17 font-semibold py-4 px-4 sm:px-8 w-[253px] text-center transition-all duration-300"
          >
            SEE OUR SUPPORTED LANGUAGES
          </Link>
        </div>
        <div className="lg:w-1/2"></div>
      </div>
      <div className="absolute right-0 bottom-0 hidden lg:block ">
        <OptimizedImage
          src="https://tomedes.gumlet.io/frontend/images/home-en/Language.webp"
          alt="globe"
          className="w-[470px] h-auto"
        />
      </div>
    </section>
  );
}
