import { Link } from "gatsby";
import React from "react";

export default function HomePageSecondComp() {
  return (
    <div className="bg-midBlue pb-32">
      <div className="w-9/12 sm:w-[70%] flex flex-col mx-auto items-center font-opensans xs:font-secondary max-w-4xl md:pt-8">
        <div className="border-8 border-t border-orange px-8 my-20"></div>
        <p className="text-26 sm:text-40 font-bold text-white text-center">
          Our professional{" "}
          <Link to="/translation-services" className="text-orange underline underline-offset-2">
            translation services
          </Link>{" "}
          allow you to be understood properly and connect deeply with your
          target audience.
        </p>
      </div>
      <div className="w-10/12 sm:w-[80%] flex flex-col mx-auto items-center font-opensans xs:font-secondary max-w-5xl md:pt-8">
        <div className="border-8 border-t border-orange px-8 my-20"></div>
        <h2 className="text-38 text-white text-center pb-16">
          What makes our translation company different?
        </h2>
      </div>
      <div className="flex lg:flex-row flex-col  justify-between mx-auto md:w-11/12 xl:w-[80%] w-11/12 flex-wrap lg:max-w-max">
        <div className="w-full lg:w-[40%] pt-10">
          <h3 className="flex flex-col lg:flex-row items-center">
            <span className="text-80 font-bold text-orange">98%</span>
            <span className="text-20 sm:text-25 text-white font-opensans xs:font-secondary font-semibold lg:pl-8 classImp text-center lg:text-left">
              Customer Satisfaction Rate
            </span>
          </h3>
          <p className="text-white text-20 classImp text-center lg:text-left">
            Our customers agree that Tomedes is the translation company to beat.
          </p>
        </div>
        <div className="w-full lg:w-[40%] pt-10 mt-2 sm:mt-0">
          <h3 className="flex flex-col lg:flex-row items-center">
            <span className="text-80 font-bold text-orange">20,000</span>
            <span className="text-20 sm:text-25 text-white font-opensans xs:font-secondary font-semibold lg:pl-8 classImp text-center lg:text-left">
              Native Translators
            </span>
          </h3>
          <p className="text-white text-20 classImp text-center lg:text-left">
            A global pool of native translators at your service, anytime,
            anywhere.
          </p>
        </div>
      </div>
      <div className="flex lg:flex-row flex-col  justify-between mx-auto md:w-11/12   xl:w-[80%] w-11/12 flex-wrap lg:max-w-max">
        <div className="w-full lg:w-[40%] 2xl:ml-14 lg:pt-20 pt-10 mt-2 sm:mt-0 ">
          <h3 className="flex flex-col lg:flex-row items-center">
            <span className="text-80 font-bold text-orange  ">15</span>
            <span className="text-20 sm:text-25 text-white font-opensans xs:font-secondary font-semibold lg:pl-8 classImp text-center lg:text-left">
              Years Of Professional Translation Experience
            </span>
          </h3>
          <p className="text-white text-20 classImp text-center lg:text-left 2xl:pl-[2px]">
            We've had more than a decade of experience optimizing our services.
          </p>
        </div>
        <div className="w-full lg:w-[40%] pt-10 lg:pt-20 mt-2 sm:mt-0">
          <h3 className="flex flex-col lg:flex-row items-center">
            <span className="text-80 font-bold text-orange">120</span>
            <span className="text-20 sm:text-25 text-white font-opensans xs:font-secondary font-semibold lg:pl-8 classImp text-center lg:text-left">
              Languages & 950+ Language Pairs
            </span>
          </h3>
          <p className="text-white text-20 classImp text-center lg:text-left">
            We have the language pair you need to bring meaning to your words.
          </p>
        </div>
      </div>
    </div>
  );
}
