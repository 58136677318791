import React from "react";
import OptimizedImage from "hooks/OptimizedImage";
// import img from ""

export default function HomepageFifthComp({ BackgroundCol, content, head, colorHead, lastHeadText, headingMobile }) {
  return (
    <section
      className={`${
        BackgroundCol
          ? BackgroundCol
          : "bg-gradient-to-t from-[#FFFFFF] to-[#E8F1F5]"
      } py-24 relative font-opensans xs:font-secondary`}
    >
      <div className="invisible lg:visible absolute top-0 left-0">
        <OptimizedImage
          src="https://tomedes.gumlet.io/frontend/images/home-en/Industry_dots.webp"
          alt="dots"
          className="w-[103px] h-[251px]"
        />
      </div>
      <div className="container mx-auto px-4">
        {!headingMobile && (
          <h2 className="text-[#00173A] text-[40px] font-bold md:text-4xl text-center font-opensans xs:font-secondary leading-10">
            {head ? head : "A Translation Agency you can "}
            <span className="text-orange leading-loose">
              {" "}
              {colorHead ? colorHead : "Trust"}
            </span>{" "}
            &nbsp; <br />
            <span className="text-[#00173A] pt-4">{lastHeadText}</span>
          </h2>
        )}
        {headingMobile && (
          <h2 className="text-[#00173A] text-[40px] font-bold text-center font-opensans xs:font-secondary leading-10">
            <span className="mb-2 block sm:inline-block">
              Trusted by &nbsp;
            </span>
            <span className="text-orange mb-2 block sm:inline-block">
              95,000+
            </span>
            <span className="sm:block sm:mt-3">
              Satisfied Clients Worldwide
            </span>
          </h2>
        )}
        <p className="text-[#00173A] text-[18px] text-center py-4 leading-relaxed px-10 lg:max-w-[62rem] lg:mx-auto">
          {content
            ? content
            : "95,000 clients have entrusted Tomedes with their business. These include private individuals, small to medium businesses, and the largest corporations, leading brands, national and local governments. But most importantly: we're here for you. Right now."}
        </p>

        <div className="mx-auto pt-10 text-center">
          <div className="grid gap-12 max-w-lg mx-auto  grid-cols-2 md:grid-cols-3 md:gap-10 lg:flex lg:flex-wrap lg:justify-center lg:space-x-6 lg:space-y-4 lg:max-w-4xl place-items-center place-content-center">
            <div className="self-end">
              <OptimizedImage
                src="https://tomedes.gumlet.io/assets/clients/amazon.svg"
                alt="amazon"
                className="w-24 h-auto"
              />
            </div>
            <OptimizedImage
              src="https://tomedes.gumlet.io/assets/clients/google.svg"
              alt="google"
              className="w-20 h-auto"
            />
            <OptimizedImage
              src="https://tomedes.gumlet.io/assets/clients/microsoft.svg"
              alt="microsoft"
              className="w-24 h-auto"
            />
            <OptimizedImage
              src="https://tomedes.gumlet.io/assets/clients/youtube.svg"
              alt="youtube"
              className="w-24 h-auto"
            />
            <OptimizedImage
              src="https://tomedes.gumlet.io/assets/clients/bloomberg.svg"
              alt="bloomberg"
              className="w-24 h-auto"
            />
            <OptimizedImage
              src="https://tomedes.gumlet.io/assets/clients/spotify.svg"
              alt="spotify"
              className="w-20 h-auto"
            />
            <OptimizedImage
              src="https://tomedes.gumlet.io/assets/clients/wix.svg"
              alt="wix"
              className="w-20 h-auto"
            />
            <OptimizedImage
              src="https://tomedes.gumlet.io/assets/clients/hsbc.svg"
              alt="hsbc"
              className="w-24 h-auto"
            />
            <OptimizedImage
              src="https://tomedes.gumlet.io/assets/images/canon.svg"
              alt="canon"
              className="w-24 h-auto"
            />
            <OptimizedImage
              src="https://tomedes.gumlet.io/assets/clients/mgm.png"
              alt="mgm"
              className="w-11 h-auto"
            />
            <OptimizedImage
              src="https://tomedes.gumlet.io/assets/clients/code.svg"
              alt="code"
              className="w-9 h-auto"
            />
            <OptimizedImage
              src="https://tomedes.gumlet.io/assets/clients/sap.svg"
              alt="sap"
              className="w-12 h-auto"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
