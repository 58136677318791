import React from "react";
import { Link } from "gatsby";
import OptimizedImage from "hooks/OptimizedImage";

function ReliableTranslation() {
  const newBg = {
    background:
      "linear-gradient(180deg, #E8F1F5 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
  };

  return (
    <section className=" pb-8 pt-10 lg:pt-20  relative" style={newBg}>
      <div className="container mx-auto px-4  lg:pb-10 ">
        <div className="lg:grid lg:grid-cols-2 lg:mx-auto lg:max-w-screen-lg mr-0 mb-24">
          <div>
            <h2 className="font-opensans xs:font-secondary font-bold text-midBlue text-40 l:pr-[260px] mb-2">
              <span className="text-[#ff7b16]">Professional </span>Translation
              Services for All Your Business Needs
            </h2>
            <p className="font-opensans text-lg text-midBlue mb-6 text-left mt-4">
              We are ready to serve all your translation needs. Our network of
              masterful translators span the globe and are strategically hired
              to provide unique, professional services for any project. No
              deadline is impossible, and with 24/7 support, we’re with you from
              start to finish. We translate all types of business documents and
              can match you with the right interpreter for business meetings,
              live events, and more.
            </p>
          </div>
          <OptimizedImage
            src="https://tomedes.gumlet.io/assets/images/new-homepage-assets/desktop/business-translation.webp"
            alt=""
            className="w-9/12 h-full ml-20 hidden lg:block"
          />
        </div>
        <div className="lg:grid lg:grid-cols-2 lg:mx-auto lg:max-w-screen-lg mr-0">
          <div className="lg:h-[432px] mb-5 lg:mb-0 relative">
            <h3 className="font-opensans xs:font-secondary font-semibold text-midBlue text-[26px] l:pr-[260px] mb-2">
              Let Our Translation Agency Connect You with Your
              <span className="text-[#ff7b16]"> Target Audience </span>
            </h3>
            <p className="font-opensans text-lg text-midBlue mb-6 text-left mt-4">
              Translation is a science and an art. At Tomedes, we ensure your
              first impression is backed by both, masterfully combining
              creativity and data-driven solutions to fit your needs. According
              to our research, companies that invest in translation are more
              likely to expand internationally, create deeper customer loyalty,
              and increase their revenue exponentially. Our professional
              translations can connect your company to the right language for
              the right audience.
            </p>
            {/* <Link
              to="/marketing-translation.php"
              className="learnmore text-midBlue underline font-opensans xs:font-secondary font-semibold text-2xl hover:text-[#ff7b16] transition-all duration-300 lg:absolute bottom-0  mb-6 lg:mb-0"
            >
              Get Started
            </Link> */}
          </div>
          <div className="lg:ml-20 lg:h-[432px] relative mt-14 lg:mt-0">
            <div>
              <h3 className="font-opensans xs:font-secondary font-semibold text-midBlue text-[26px] l:pr-[260px] mb-2">
                <span className="text-[#ff7b16]">Cost-Effective </span>
                Professional Translation Services
              </h3>
              <p className="font-opensans text-lg text-midBlue mb-6 text-left mt-">
                Tomedes offers cost-effective and professional translation
                services, online at your fingertips. We believe in transparency
                in our services, and this includes fair prices. Our project
                management team is always online to respond to questions about
                quotes and prices so you know from the very beginning your
                translation budget. Get a free, no-obligation quote today.
              </p>
              {/* <Link
                to="/quote"
                className="learnmore text-midBlue underline font-opensans xs:font-secondary font-semibold text-2xl hover:text-[#ff7b16] transition-all duration-300 lg:absolute bottom-0"
              >
                Get Started
              </Link> */}
            </div>
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-1 lg:mx-auto lg:max-w-screen-lg mr-0 mb-12 mt-20 xl:mt-24">
          <div className="bg-learnmoreBg bg-no-repeat bg-cover bg-center lg:h-[160px] px-4 py-6 lg:px-[100px] lg:py-7">
            <p className="text-center text-white font-opensans xs:font-secondary text-24 sm:text-[28px]">
              Need Professional Translations?
              <br /> Get a{" "}
              <Link
                to="/quote"
                className="underline hover:text-midBlue transition-all duration-300"
              >
                free quote
              </Link>{" "}
              now.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ReliableTranslation;
