import React from "react";
import { Link } from "gatsby";

function AboutUs() {
  return (
    <section className="w-full  bg-aboutus bg-no-repeat bg-cover bg-center py-36">
      <div className="w-11/12 lg:w-8/12 mx-auto  flex flex-col items-center justify-center max-w-7xl">
        <h2 className="font-opensans xs:font-secondary font-bold text-midBlue text-40 l:pr-[260px] mb-2 text-center">
          About Our Translation Company
        </h2>
        <p className="font-opensans text-lg text-midBlue mb-6 text-center mt-2">
          Tomedes is a translation agency known for expert services spanning a
          range of industries, fields, and cultures. Established in 2007, we now
          have linguists in 5 continents, and offices in the UK, the USA, Asia,
          and the Middle East. We are committed to our clients, the beating
          heart of our philosophy. We aim to broaden your horizons with our
          smart, online, and local operations. We match you with the right
          translation expertise, every time, whether you’re in the United States
          or abroad.
        </p>
        <Link
          to="/contactus.php"
          className="mt-2 uppercase rounded-full bg-orange text-white hover:bg-white hover:text-[#010101]  cursor-pointer text-17 font-semibold py-4 px-8 w-[253px] text-center transition-all duration-300"
        >
          Contact Us
        </Link>
      </div>
    </section>
  );
}

export default AboutUs;
