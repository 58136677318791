import React from "react";
import { Link } from "gatsby";
import OptimizedImage from "hooks/OptimizedImage";

export default function HomepageFourthComp() {
  return (
    <div className="bg-gradient-to-t from-[#E8F1F5] to-[#FFFFFF] pt-32 font-opensans xs:font-secondary ">
      <div className="mainContainer w-full lg:w-3/4 justify-center items-center mx-auto lg:max-w-7xl">
        <h2 className="hidden text-[40px] w-11/12 sm:flex justify-center items-center mx-auto font-bold text-[#00173A] text-center">
          Professional Translation Services for Any Industry
        </h2>
        {/* <h2 className="hidden text-[40px] w-10/12 sm:flex justify-center items-center mx-auto font-bold text-[#00173A] text-center">
          {" "}
          number of complex industries
        </h2> */}
        <h2 className="text-[32px] w-10/12 sm:hidden  mx-auto font-bold text-[#00173A] text-center">
          Professional Translation Services for Any Industry
        </h2>
        <p className="text-[18px] text-center w-full pt-6 leading-loose px-[1rem] sm:px-0">
          Tomedes’ document translation has provided the highest quality of
          translation to businesses and organizations worldwide, and in every
          language. We don’t just translate words–we capture your true meaning.
        </p>

        <div className="CardContainer flex flex-wrap justify-center lg:justify-between w-full pt-16 pb-20 px-4">
          <div className="card w-full lg:w-[40%]   relative mb-16 sm:mb-8">
            <OptimizedImage
              src="https://tomedes.gumlet.io/assets/images/new-homepage-assets/desktop/legal-translation.webp"
              alt="legal translation"
              className="flex justify-center md:justify-start mx-auto md:-mx-0 items-center"
            />
            <h3 className="text-[26px] font-semibold pt-5 pb-3">Legal</h3>
            <p className="text-[18px] pb-8">
              Law is a highly specialized field. Our professional legal
              translators are adept in the various fields of law.{" "}
              <span className="text-orange">
                We translate affidavits, patents, contracts, and other legal
                documents.
              </span>{" "}
              If you're in need of translation, our legal experts are up for the
              task.
            </p>
            {/* <Link
              to="/legal-translation.php"
              className="text-[#00173A] learnmore  text-[22px] font-bold underline absolute bottom-0 -left-0 hover:text-orange transition-all duration-300"
            >
              Get Started
            </Link> */}
          </div>

          <div className="card w-full lg:w-[40%]  pt-10 lg:pt-0 mb-16 sm:mb-8 relative">
            <OptimizedImage
              src="https://tomedes.gumlet.io/assets/images/new-homepage-assets/desktop/medical-translation.webp"
              alt="medical translation"
              className="flex justify-center md:justify-start mx-auto md:-mx-0 items-center"
            />
            <h3 className="text-[26px] font-semibold pt-5 pb-3">Medical</h3>
            <p className="text-[18px] pb-8">
              Translation is an integral part of healthcare, abiding by
              regulations from the FDA and backed by an in-depth understanding
              of the life sciences.{" "}
              <span className="text-orange">
                We translate vital medical documents, discharge records, patient
                forms and academic journals.
              </span>{" "}
            </p>
            {/* <Link
              to="/medical-translation.php"
              className="text-[#00173A]  learnmore text-[22px] font-bold underline absolute bottom-0 hover:text-orange transition-all duration-300"
            >
              Get Started
            </Link> */}
          </div>

          <div className="card w-full lg:w-[40%]  pt-10 lg:pt-8 mb-16 sm:mb-8">
            <OptimizedImage
              src="https://tomedes.gumlet.io/assets/images/new-homepage-assets/desktop/financial-translation.webp"
              alt="financial translation"
              className="flex justify-center md:justify-start mx-auto md:-mx-0 items-center"
            />
            <h3 className="text-[26px] font-semibold pt-5 pb-3">Financial</h3>
            <p className="text-[18px] leading-relaxed pb-5">
              We are here to support the bottomline of your business.{" "}
              <span className="text-orange">
                We translate loan documents, contracts, mortgage papers,
                financial documents, statements of accounts, credit reports and
                more.
              </span>{" "}
              Provided by experts in finance and financial terminology, our
              translation combines linguistic accuracy and attention to detail.
            </p>
            {/* <Link
              to="/financial-translation.php"
              className="text-[#00173A] learnmore text-[22px] font-bold underline hover:text-orange transition-all duration-300"
            >
              Get Started
            </Link> */}
          </div>

          <div className="card w-full lg:w-[40%] relative pt-10 lg:pt-8 mb-8">
            <OptimizedImage
              src="https://tomedes.gumlet.io/assets/images/new-homepage-assets/desktop/technical-translation.webp"
              alt="technical translation"
              className="flex justify-center md:justify-start mx-auto md:-mx-0 items-center"
            />
            <h3 className="text-[26px] font-semibold pt-5 pb-3">Technical</h3>
            <p className="text-[18px] pb-8">
              Translation should transform even the most complex procedures for
              global use.{" "}
              <span className="text-orange">
                We translate technical documentation in any language: from
                manuals , architectural plans, and more.
              </span>{" "}
              Our company combines the best of all worlds–languages, applied
              sciences, advanced mathematics, and more.
            </p>
            {/* <Link
              to="/technical-translation.php"
              className="text-[#00173A]  learnmore text-[22px] font-bold underline absolute bottom-0 hover:text-orange transition-all duration-300"
            >
              Get Started
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
}
